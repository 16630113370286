import logoLight from './Logo-light.svg';
import logoDark from './Logo-dark.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <picture>
          <source srcSet={logoLight} media="(prefers-color-scheme: dark)" alt="logo"/>
          <img src={logoDark} className="App-logo" alt="logo"/>
        </picture>
        <p>
          For any inquiries, please contact us at {""}
          <a 
          className="App-link" 
          href="mailto:contact@xtraor.com?subject=Inquiry about "
          target="_blank"
          rel="noopener noreferrer"
          >
          contact@xtraor.com
          </a>.
        </p>
      </header>
    </div>
  );
}

export default App;
